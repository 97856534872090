import React from 'react'
import { IProductSearch } from '@core/api/Products/types'
import ProductCard from '@original/components/shared/ProductCard/index'
import ProductPrice from '@core/components/shared/ProductPrice'
export * from '@original/components/shared/ProductCard/index'
import style from './index.module.less'

interface OwnProps {
  product: IProductSearch
}

const HazetProductCard: React.FC<OwnProps> = ({ product }) => {
  return (
    <ProductCard
      product={product}
      slots={{
        price: () => {
          return (
            product.mainVariant.packagingUnits[0].price && (
              <div className={style.priceHolder}>
                <ProductPrice
                  price={product.mainVariant.packagingUnits[0].price}
                  strikePrice={
                    product.mainVariant.packagingUnits[0].strikeThroughPrice
                      ?.price
                  }
                  hideAdditionalCosts
                  showDiscountHint={true}
                />
              </div>
            )
          )
        },
      }}
    />
  )
}

export default HazetProductCard
